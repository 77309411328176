.card {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 10px 36px rgba(53, 54, 54, 0.09);
  border-radius: 28px;
  height: 867px;
  display: flex;
  margin: 150px 0;
}

@media screen and (max-width: 768px) {
  .card {
    margin: 80px 0;
  }
}
