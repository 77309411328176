.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  .labelArea {
    display: flex;
    justify-content: flex-start;
    column-gap: 7px;

    .label {
      font-family: "Poppins Semibold";
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */

      color: #585858;
    }

    .question {
      font-family: "Poppins Light";
      font-style: italic;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height */

      color: #757575;
    }
  }
  .disabled {
    background-color: #f5f5f5 !important;
  }

  .loadingIcon {
    transition: 0.5 ease-in-out;
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
  }
  .inputContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    background: #ffffff;
    border: 1px solid #585858;
    border-radius: 6px;
    transition: 0.3s ease-in-out;

    max-width: 300px;
    height: 53px;

    .StripeElement {
      width: 100%;
    }

    input {
      width: 100%;
      outline: none;
      border: none;

      font-family: "Poppins Semibold";
      font-size: 18px;
      line-height: 27px;
      /* identical to box height */

      color: #575757;
    }
    .iconWrapper {
      width: 28px;
      height: 28px;
      border: none;
      background: transparent;
    }

    &:focus-within {
      border: 2px solid #fe5601;
    }
  }
}
@media screen and (max-width: 1000px) {
  .wrapper {
    width: 100%;

    .inputContainer {
      max-width: 100%;
    }
  }
}
@media screen and (max-width: 768px) {
  .card {
    width: 95% !important;
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
