.buttonWrapper {
  width: 100%;
  height: 64px;
  padding-left: 48px;
  display: flex;
  justify-content: center;
  transition: 0.3s ease-in-out;

  .button {
    width: 100%;
    column-gap: 20px;
    display: flex;
    align-items: center;
    font-family: "Poppins Semibold";

    font-size: 18px;
    line-height: 27px;

    color: #efefef;
    border: none;
    outline: none;
    background-color: transparent;

    .icon {
      width: 28px;
      height: 28px;
      color: #ffffff;
      fill: #ffffff;
    }
  }
}

.active {
  background: #3a3a3a !important;

  .button {
    color: #fe5601 !important;

    .icon {
      color: #fe5601 !important;
    }
  }
}
