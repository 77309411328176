.card {
  background: #ffffff;
  box-shadow: 0px 10px 36px rgba(53, 54, 54, 0.09);
  border-radius: 28px;
  width: (100vw / 1.4);
  min-height: 768px;
  height: 768px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .banner {
    width: 40%;
    height: 100%;
    background-image: url("../../assets/images/banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 28px 0 0 28px;
  }
  .pageLoading {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(60, 60, 60, 0.66);
    backdrop-filter: blur(11px);
    width: 100%;
    height: 100%;

    .loadingIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transition: 0.5 ease-in-out;
      -webkit-animation: rotating 1s linear infinite;
      -moz-animation: rotating 1s linear infinite;
      -ms-animation: rotating 1s linear infinite;
      -o-animation: rotating 1s linear infinite;
      animation: rotating 1s linear infinite;
    }
  }
  .content {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .logo {
      display: flex;
      width: 90%;
      margin-top: 50px;
      column-gap: 13px;

      img {
        width: 36px;
        height: 36px;
      }

      .caption {
        font-family: "Bebas Neue";
        font-style: normal;
        font-weight: 400;
        font-size: 27px;
        line-height: 32px;

        color: #3c3c3c;
      }
    }
    .inputRow {
      width: 90%;
      display: flex;
      justify-content: space-between;
      margin: 9px;
      column-gap: 38px;
    }
    .highlight {
      color: #fe5601;
      font-family: "Poppins Regular";
    }
    .paymentSuccess {
      display: flex;
      flex-direction: column;
      row-gap: 2px;

      margin-top: 42px;

      font-family: "Poppins Semibold";
      font-style: normal;
      font-size: 24px;
      line-height: 36px;
      /* identical to box height */

      color: #fe5601;
      align-items: center;

      .transactionId {
        font-family: "Poppins Regular";

        font-size: 16px;
        line-height: 24px;
        word-break: break-word;
        text-align: center;
        /* identical to box height */

        color: #585858;
      }
    }

    .hairline {
      width: 168px;
      height: 1px;

      background: #d8d8d8;
      margin-top: 12px;
    }

    .textCaption {
      margin-top: 62px;
      margin-bottom: 35px;
      font-family: "Poppins Semibold";
      font-size: 24px;
      line-height: 36px;
      width: 90%;
      /* identical to box height */

      color: #585858;
    }
    .agreement {
      width: 90%;
      margin-top: 38px;
      display: flex;
      justify-content: center;

      .checkboxLabel {
        margin-left: 10px;
        font-family: "Poppins Semibold";

        .link {
          color: #fe5601;
          cursor: pointer;
        }
      }
    }
    .paypalButton {
      margin-top: 48px;
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 47px;

      .buttonWrapper {
        width: 346px;
      }

      iframe {
        width: 346px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .card {
    flex-direction: column !important;
    height: auto !important;

    .banner {
      width: 100% !important;
      height: 300px !important;
      background-position: unset !important;
      border-radius: 28px 28px 0 0 !important;
    }
    .content {
      margin-top: 30px;
      width: 90% !important;

      .inputRow {
        flex-direction: column;
        row-gap: 18px;

        .wrapper {
          width: 100%;

          .inputContainer {
            max-width: 100% !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .card {
    width: 95% !important;
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
