blockquote {
  color: rgb(37, 37, 37);
  margin: 0;
  padding: 10px 30px;
  border-left: 0.5em rgb(187, 186, 186) solid;
  font-size: 16px;
}
h1 {
  font-family: "Bebas Neue";
  font-size: 64px;
}
.youtubeLogo img {
  max-width: 100px;
}
.rightSection {
  display: flex;
  align-items: center;

  h5 {
    background-color: rgb(222, 222, 222);
    padding: 20px;
    border-radius: 8px;
  }
}
.contactImage {
  img {
    height: 100%;
    object-fit: cover;
  }
}
h3 {
  font-size: 1.6rem;
}
img {
  border-radius: 8px;
  max-width: 100%;
}
.youtubeVideos {
  padding: 50px !important;

  h3 {
    text-align: center;
    margin-bottom: 20px;
  }
  img {
    max-height: 300px;
    object-fit: cover;
  }
}

.col-md-6 {
  padding: 20px;
  img {
    width: 100%;
  }
  img[alt="Instagram"] {
    width: 53px;
  }
}
