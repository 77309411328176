.dashContainer {
  padding: 58px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  .mobileMenuSwitch {
    button {
      width: 40px;
      height: 40px;
      border: 1px solid #e4e4e4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background: #fff;
      row-gap: 5px;

      .line {
        width: 20px;
        height: 3px;
        background-color: #888888;
        border-radius: 5px;
      }
    }
  }

  span {
    font-family: "Poppins Semibold";

    font-size: 24px;

    color: #3c3c3c;
  }

  .downloadWrapper {
    margin-top: 16px;
    word-break: break-all;
  }
  .downloadText {
    margin-top: 10px;
    font-size: 16px;
    line-height: auto;
  }
  .videoWrapper {
    margin-top: 97px;
    font-family: "Poppins Semibold";
    font-size: 24px;
    line-height: 36px;

    color: #828282;
  }
}
.videoiframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .dashContainer {
    padding: 20px;
    .videoWrapper {
      margin-top: 50px;
    }
  }
  .videoiframe {
    width: 80%;
  }
}
