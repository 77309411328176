.container {
  min-height: calc(100vh - 84px);
  margin-top: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  margin-bottom: 50px;
}

.card {
  background: #ffffff;
  box-shadow: 0px 10px 36px rgba(53, 54, 54, 0.09);
  border-radius: 28px;
  width: (100vw / 1.4);
  min-height: 768px;
  height: 768px;
  overflow-y: overlay;
  scroll-behavior: smooth;
  padding: 30px;
}

@media screen and (max-width: 768px) {
  .card {
    border-radius: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .container {
    margin-bottom: 100px;
    padding: 0 !important;
  }
  h1 {
    font-size: 42px;
  }
  h3 {
    font-size: 1.4rem;
  }
}
