.passContainer {
  padding: 58px;
  display: flex;
  flex-direction: column;
  span {
    font-family: "Poppins Semibold";

    font-size: 24px;
    line-height: 36px;

    color: #3c3c3c;
  }

  .passwordForm {
    margin-top: 56px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    .flexBox {
      display: flex;
      column-gap: 20px;
      margin-bottom: 20px;
    }

    .error {
      font-family: "Poppins Semibold";

      font-size: 14px;
      line-height: 36px;
      color: crimson;
    }
  }

  .downloadWrapper {
    margin-top: 16px;
    word-break: break-all;
  }
  .videoWrapper {
    margin-top: 97px;
    font-family: "Poppins Semibold";
    font-size: 24px;
    line-height: 36px;

    color: #828282;
  }
}
