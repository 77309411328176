@font-face {
  font-family: "Bebas Neue";
  src: url(./assets/fonts/BebasNeue-Regular.ttf);
}
@font-face {
  font-family: "Poppins Thin";
  src: url(./assets/fonts/Poppins-Thin.ttf);
}
@font-face {
  font-family: "Poppins Light";
  src: url(./assets/fonts/Poppins-Light.ttf);
}
@font-face {
  font-family: "Poppins Regular";
  src: url(./assets/fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: "Poppins Medium";
  src: url(./assets/fonts/Poppins-Medium.ttf);
}
@font-face {
  font-family: "Poppins Semibold";
  src: url(./assets/fonts/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: "Poppins Bold";
  src: url(./assets/fonts/Poppins-Bold.ttf);
}
@font-face {
  font-family: "Bebas Neue";
  src: url("./assets/fonts/BebasNeue-Regular.ttf");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  background: #f5f5f5;
}
