.container {
  width: 100%;
  height: 100vh;
  padding: 84px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    width: 100%;
    max-width: 400px;
    background-color: #ffffff;
    box-shadow: 0px 10px 36px rgba(53, 54, 54, 0.09);
    border-radius: 28px;
    display: flex;
    margin: 150px 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      margin-top: 30px;
    }

    .signupForm {
      margin-top: 50px;
      margin-bottom: 20px;

      .inputContainer {
        width: 300px;
        margin: 10px 0;
      }
    }

    .title {
      font-family: "Poppins Semibold";
      font-size: 28px;
      margin-top: 32px;
    }

    .textContainer{
        color: #6f7c8e;
        word-wrap: break-word;
        max-width: 300px;
    }

    @media screen and (max-width: 768px) {
      margin: 80px 0;
    }
  }
}
@media screen and (max-width: 768px) {
  .container {
    padding: 0;
  }
}
