.buttonWrapper {
  max-width: 346px;
  width: 100%;
  height: 57px;

  button {
    width: 100%;
    height: 100%;

    background-color: #fe5601;
    color: #ffffff;
    border-radius: 5px;
    transition: 0.3s ease-in-out;

    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins Semibold";

    font-size: 18px;
    line-height: 27px;

    border: none;
    outline: none;

    &:hover {
      background-color: #e86b2d;
    }

    &:disabled {
      background-color: #d48862;
    }
  }
}
.loadingIcon {
  transition: 0.5 ease-in-out;
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
