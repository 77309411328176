.card {
  background: #ffffff;
  box-shadow: 0px 10px 36px rgba(53, 54, 54, 0.09);
  border-radius: 28px;
  width: (100vw / 1.4);
  min-height: 768px;
  height: 768px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .closeButton {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;

    button {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }

  .banner {
    width: 40%;
    height: 100%;
    background-image: url("../../assets/images/banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 28px 0 0 28px;
  }
  .content {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .caption {
      font-family: "Bebas Neue";
      font-size: 64px;
      line-height: 77px;
      margin-top: 28px;
      text-align: center;
      width: 90%;
      /* identical to box height */

      color: #3c3c3c;
    }
    .hairline {
      width: 168px;
      height: 1px;

      background: #d8d8d8;
      margin-top: 12px;
    }
    .textContent {
      width: 90%;
      max-width: 570px;
      margin-top: 48px;

      .textCaption {
        font-family: "Poppins Semibold";
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */

        color: #585858;
      }
      .textParagraph {
        margin-top: 16px;
        font-family: "Poppins Regular";
        font-size: 16px;
        line-height: 23px;

        color: #494949;

        .link {
          font-family: "Poppins Semibold";
          color: #fe5601;
          padding-left: 10px;
          cursor: pointer;
        }
      }
    }
    .agreement {
      width: 90%;
      margin-top: 38px;
      display: flex;
      justify-content: center;

      .checkboxLabel {
        margin-left: 10px;
        font-family: "Poppins Semibold";

        .link {
          color: #fe5601;
          cursor: pointer;
        }
      }
    }
    .paypalButton {
      margin-top: 48px;
      width: 100%;
      display: flex;
      justify-content: center;

      .buttonWrapper {
        width: 346px;
      }

      iframe {
        width: 346px;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .card {
    flex-direction: column !important;
    height: auto !important;

    .banner {
      width: 100% !important;
      height: 300px !important;
      background-position: unset !important;
      border-radius: 28px 28px 0 0 !important;
    }
    .content {
      margin-top: 30px;
      width: 90% !important;

      .inputRow {
        flex-direction: column;
        row-gap: 18px;

        .wrapper {
          width: 100%;

          .inputContainer {
            max-width: 100% !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .card {
    width: 95% !important;
  }
}
