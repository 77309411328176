.container {
  min-height: calc(100vh - 84px);
  margin-top: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 30px 150px 30px;
}
.wrapper {
  margin-bottom: 50px;
}
@media screen and (max-width: 768px) {
  .card {
    width: 100vw;
    height: 100vh;
    border-radius: 0px;
  }
  .container {
    margin-bottom: 150px;
    padding: 0;
  }
}
