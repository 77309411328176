.wrapper {
  width: 100%;
  height: 100vh;

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .hero {
      position: relative;
      width: 100%;
      height: calc(100% - 84px - 89px);
      margin-top: 84px;
      background: radial-gradient(
        65.03% 131.84% at 89.14% 67.11%,
        #40332c 0%,
        rgba(0, 0, 0, 0.9375) 100%
      );

      .scrollDown {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
      }

      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: calc(100vh - 84px - 89px);
        width: 100%;

        .heroTitle {
          display: flex;
          flex-direction: column;
          z-index: 9;

          .bakers {
            font-family: "Bebas Neue";
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 36px;
            /* identical to box height */

            color: #ffffff;
          }
          .title {
            font-family: "Bebas Neue";
            font-style: normal;
            font-weight: 400;
            font-size: 144px;
            line-height: 173px;
            /* identical to box height */

            color: #ffffff;
          }
          .textContent {
            font-family: "Poppins Regular";
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;

            color: #df9b78;
          }
          .buttonContainer {
            margin-top: 40px;
          }
        }
      }

      .rectangles {
        position: absolute;
        right: 0;
        width: 540px;
        max-height: 100%;
        padding: 0;
        z-index: 0;
      }

      .screenshot {
        width: 400px;
        z-index: 1;
      }
    }
  }
  .orangeContent {
    background-color: #fe5601;
    padding: 100px 0 200px 0;
    display: flex;

    .title {
      font-family: "Bebas Neue";
      font-style: normal;
      font-weight: 400;
      font-size: 64px;
      line-height: 77px;
      width: 100%;
      /* identical to box height */
      text-align: center;
      color: #ffffff;
    }
    .textContent {
      margin-top: 80px;
      font-family: "Poppins Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 42px;
      text-align: center;

      color: #ffffff;
    }
    .footerText {
      margin-top: 80px;
      font-family: "Poppins Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
}
@media screen and (max-width: 768px) {
  .wrapper {
    .row {
      flex-direction: column;
      .hero {
        .row {
          height: auto;
          .heroTitle {
            .title {
              font-size: 56px;
              line-height: 80px;
            }
            .textContent {
              font-size: 18px;
              line-height: 27px;
            }
          }
        }
        .screenshot {
          height: 300px;
          width: auto;
          margin-bottom: 50px;
        }
      }
    }
    .orangeContent {
      .title {
        font-size: 48px;
        line-height: 58px;
      }
      .textContent {
        font-size: 24px;
        line-height: 36px;
      }
      .footerText {
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
}
