.mobile {
  position: fixed;
  transform: translateX(-100%);
  width: 100% !important;
  transition: all 0.3s ease-in-out;

  border-radius: 0 !important;
  height: 100vh !important;
  z-index: 99;
}
.open {
  transform: translateX(0);
}
.container {
  height: 100%;
  min-width: 350px;
  background-color: #292929;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 28px 0px 0px 28px;

  .closeButton {
    position: absolute;
    right: 20px;
    top: 20px;

    button {
      border: none;
      outline: none;
      background-color: transparent;
    }
  }

  .userInfo {
    margin-top: 61px;
    display: flex;
    flex-direction: column;

    .nameContainer {
      display: flex;
      flex-direction: column;
      margin-top: 33px;

      .label {
        text-align: center;
      }
      .primaryText {
        text-align: center;
      }
    }
    .statusContainer {
      display: flex;
      flex-direction: column;
      margin-top: 27px;

      .label {
        text-align: center;
      }
      .highlightText {
        text-align: center;
      }
    }
  }
  .buttonContainer {
    margin-top: 69px;
    width: 100%;
  }
}
.label {
  font-family: "Poppins Semibold";
  font-size: 14px;
  line-height: 21px;
  color: #939393;
}
.primaryText {
  font-family: "Poppins Semibold";
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: #efefef;
}
.highlightText {
  font-family: "Poppins Semibold";
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: #fe5601;
}
