.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  background-color: #fff;
  z-index: 2;

  .hairline {
    width: 168px;
    height: 1px;
    background-color: #d8d8d8;
  }
  .links {
    display: flex;
    width: 460px;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    .link {
      display: flex;
      align-items: center;
      column-gap: 20px;

      a {
        text-decoration: none;
        color: #000;
        font-family: "Poppins Medium";

        display: flex;
        column-gap: 10px;
        align-items: center;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .footer {
    .links {
      width: 100%;
      flex-direction: column;
      row-gap: 20px;
      align-items: center;
      justify-content: center;
    }
  }
}
