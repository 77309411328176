.nav-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 84px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #000000;
  box-shadow: 0px 4px 36px rgba(53, 54, 54, 0.09);
  z-index: 99;

  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;

    column-gap: 15px;
    margin-left: 26px;

    .logo-text {
      font-family: "Bebas Neue";
      font-size: 34px;
      color: #fff;
    }
  }

  .profileActive {
    border-radius: 360px;
    border: 3px solid #fe5601;
    padding: 2px;
  }

  .nav-links-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 50%;
    padding: 50px 0;

    .link {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      a {
        font-family: "Bebas Neue";
        font-size: 34px;

        text-decoration: none;
        line-height: 41px;
        /* identical to box height */

        color: #fff;
        transition: 0.3s ease-in-out;

        &:hover {
          color: #fe5601;
        }
      }
    }
    .active {
      a {
        color: #fe5601;
      }
    }
  }

  .burger-menu {
    display: none;
    flex-direction: column;
    row-gap: 5px;
    position: fixed;
    right: 20px;
    transition: 0.3s ease-in-out;

    .line {
      border-radius: 8px;
      width: 28px;
      height: 5px;
      background: #fe5601;
    }
  }
}

@media screen and (max-width: 1000px) {
  .nav-links-wrapper {
    flex-direction: column;
    position: fixed;
    background: #fe5601;
    width: 100% !important;
    height: calc(100vh - 84px);
    top: 84px;
    right: -100%;
    transition: 0.3s ease-in-out;

    .active {
      a {
        color: #ffffff !important;
      }
    }
  }
  .burger-menu {
    display: flex !important;

    .line {
      transition: 0.3s ease-in-out;
    }
  }

  .menu-active {
    .line:nth-child(1) {
      transform: translateY(10px) rotate(45deg);
    }
    .line:nth-child(2) {
      opacity: 0;
    }
    .line:nth-child(3) {
      transform: translateY(-10px) rotate(-45deg);
    }
  }
  .active {
    right: 0 !important;
  }
  a {
    &:hover {
      color: #ffffff !important;
    }
  }
}

//anims
.scale-up-center {
  a {
    -webkit-animation: scale-up-right 0.7s
      cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
    animation: scale-up-right 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
  }
}

@-webkit-keyframes scale-up-right {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
}
@keyframes scale-up-right {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
}
